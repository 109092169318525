import { MERGE_ACADEMIC_TERMS } from './actionTypes';

var setAcademicTerms = function setAcademicTerms(academicTerms) {
  return {
    type: MERGE_ACADEMIC_TERMS,
    payload: Object.fromEntries(academicTerms.map(function (academicTerm) {
      return [academicTerm.id, academicTerm];
    }))
  };
};

var setAcademicTerm = function setAcademicTerm(academicTerm) {
  return setAcademicTerms([academicTerm]);
};

export { setAcademicTerms, setAcademicTerm };