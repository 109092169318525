import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import Head from 'next/head'
import { StudySelector } from '../components/Landing/StudySelector'
import { CustomTheme } from '../themes/main'
import { MainLandingpageLayout } from '../page-components/landing/MainLandingpageLayout'
import GirlReadingBooks from '../svg/girl-reading-books.svg'
import GirlWithWebpage from '../svg/girl-with-webpage.svg'
import ManWithDog from '../svg/man-with-dog.svg'
import SantaWithPC from '../svg/santa-with-pc.svg'

const useStyles = makeStyles((theme: CustomTheme) => ({
  mainContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  girlReadingBooks: {
    width: '100%'
  },
  smallImage: {
    width: '100%'
  },
  studySelectorWrapper: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 0
    }
  },
  boxTitle: {
    fontWeight: 'normal'
  },
  [theme.breakpoints.up('md')]: {
    girlReadingBooksWrapper: {
      paddingLeft: theme.spacing(16)
    },
    firstBlock: {
      marginTop: theme.spacing(16)
    }
  }
}))
export default function Home(): JSX.Element {
  const classes = useStyles()
  return (
    <>
      <Head>
        <title>Mit Pensum</title>
        <meta
          name='Description'
          content='Find din pensumliste på Mit Pensum. Mit Pensum holder styr på dit pensum og påminder dig, når nye pensumlister for dit studie bliver udgivet.'
        />
      </Head>
      <MainLandingpageLayout
        containerProps={{ className: classes.mainContainer }}
      >
        <Box mb={16} className={classes.firstBlock}>
          <Grid container direction={'row'} spacing={2} alignItems={'center'}>
            <Grid item className={classes.studySelectorWrapper} xs={12} md={4}>
              <StudySelector />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box className={classes.girlReadingBooksWrapper}>
                <GirlReadingBooks className={classes.girlReadingBooks} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container direction={'row'} alignItems={'stretch'} spacing={4}>
          <Grid item xs={12} md={4}>
            <Box
              p={6}
              border={1}
              borderRadius={6}
              borderColor={'lightBlue.main'}
              height={'100%'}
            >
              <Grid container direction={'row'} justify={'center'} spacing={4}>
                <Grid item xs={12}>
                  <GirlWithWebpage className={classes.smallImage} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align={'center'}
                    variant={'h3'}
                    className={classes.boxTitle}
                  >
                    MitPensum.dk er den eneste side med officielle pensumlister
                    fra KU og CBS
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              p={6}
              border={1}
              borderRadius={6}
              borderColor={'lightBlue.main'}
              height={'100%'}
            >
              <Grid container direction={'row'} justify={'center'} spacing={4}>
                <Grid item xs={12}>
                  <ManWithDog className={classes.smallImage} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align={'center'}
                    variant={'h3'}
                    className={classes.boxTitle}
                  >
                    Få automatisk besked når der er nye pensumlister
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              p={6}
              border={1}
              borderRadius={6}
              borderColor={'lightBlue.main'}
              height={'100%'}
            >
              <Grid container direction={'row'} justify={'center'} spacing={4}>
                <Grid item xs={12}>
                  <SantaWithPC className={classes.smallImage} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align={'center'}
                    variant={'h3'}
                    className={classes.boxTitle}
                  >
                    Direkte bestilling af hele dit pensum - eller nøjagtigt de
                    fag du følger
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </MainLandingpageLayout>
    </>
  )
}
