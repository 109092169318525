import { MERGE_STUDIES } from './actionTypes';

var setStudies = function setStudies(studies) {
  return {
    type: MERGE_STUDIES,
    payload: Object.fromEntries(studies.map(function (study) {
      return [study.id, study];
    }))
  };
};

var setStudy = function setStudy(study) {
  return setStudies([study]);
};

export { setStudies, setStudy };