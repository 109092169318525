export var localeCompare = function localeCompare(a, b) {
  return a.localeCompare(b, 'da');
};
export var dateCompare = function dateCompare(a, b) {
  return a.getTime() - b.getTime();
};
export var priceFormat = function priceFormat(price) {
  return "".concat(price.toLocaleString('da-DK', {
    currency: 'DKK',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }), " kr");
};
export var dateFromJSON = function dateFromJSON(str) {
  return new Date(str);
};