import { MERGE_INSTITUTIONS } from './actionTypes';

var setInstititions = function setInstititions(institutions) {
  return {
    type: MERGE_INSTITUTIONS,
    payload: Object.fromEntries(institutions.map(function (institution) {
      return [institution.id, institution];
    }))
  };
};

var setInstitition = function setInstitition(institution) {
  return setInstititions([institution]);
};

export { setInstititions, setInstitition };